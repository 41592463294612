import dayjs from 'dayjs'

export const columns = [
	{
		prop: 'spName',
		label: '业务事件',
		width: 300,
	},
	{
		prop: 'userName',
		label: '发起人',
	},
	{
		prop: 'createTime',
		label: '审批发起时间',
		width: 250,
		formatter: function (row, column, cellValue, index) {
			if (!cellValue) return '--'
			return dayjs(cellValue).format('YYYY-MM-DD HH:mm:ss')
		},
	},
	{
		prop: 'spStatus',
		label: '审批状态',
		formatter: function (row, column, cellValue, index) {
			// 1-审批中；2-已通过；3-已驳回；4-已撤销
			switch (cellValue) {
				case 1:
					return '审批中'
				case 2:
					return '已通过'
				case 3:
					return '已驳回'
				case 4:
					return '已撤销'
			}
		},
	},
	{
		prop: 'finishTime',
		label: '审批完成时间',
		width: 250,
		formatter: function (row, column, cellValue, index) {
			if (!cellValue) return '--'
			return dayjs(cellValue).format('YYYY-MM-DD HH:mm:ss')
		},
	},
	{
		prop: 'sendTo',
		label: '审批渠道',
		formatter: function () {
			return '钉钉审批'
		},
	},
	{
		prop: 'status',
		label: '提交状态',
		formatter: function (row, column, cellValue, index) {
			return cellValue === 0 ? '失败' : '成功'
		},
	},
]

export const filters = {
	expand: false,
	filters: [
		{
			label: '搜索',
			attr: 'keyword',
			type: 'search',
			placeholder: '输入业务事件/流水号/发起人',
			clearable: true,
		},
		{
			attr: 'spStatus',
			label: '审批状态',
			type: 'select',
			placeholder: '请选择审批状态',
			// 1-审批中；2-已通过；3-已驳回；4-已撤销
			options: [
				{ name: '审批中', code: 1 },
				{ name: '已通过', code: 2 },
				{ name: '已驳回', code: 3 },
				{ name: '已撤销', code: 4 },
			],
		},
		{
			attr: 'createTime',
			label: '审批发起时间',
			type: 'dateTime',
			placeholder: '选择发送时间',
		},
	],
}

export const rules = {
	addAndEdit: {
		name: [{ required: true, message: '请输入角色名称', trigger: 'blur' }],
		dataScope: [{ required: true, message: '请选择数据范围', trigger: 'change' }],
	},
}
