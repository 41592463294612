<template>
	<el-dialog
		v-model="isShowDialog"
		width="650px"
		class="role-dialog-container"
		:title="dialogTitle"
		:before-close="close"
		:close-on-click-modal="false"
		append-to-body
		destroy-on-close
	>
		<div class="dialog-body" v-loading="isLoading">
			<el-form
				ref="ruleFormRef"
				label-width="150px"
				size="large"
				disabled
				:rules="formRule"
				:model="formData"
			>
				<el-row>
					<el-col :span="18">
						<el-form-item label="业务事件" :prop="spName">
							<el-input
								v-model="formData.spName"
								placeholder="请输入角色名称"
								maxlength="20"
								show-word-limit
								clearable
							/>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="18">
						<el-form-item label="发起人" :prop="userName">
							<el-input
								v-model="formData.userName"
								placeholder="请输入角色名称"
								maxlength="20"
								show-word-limit
								clearable
							/>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="18">
						<el-form-item label="审批发起时间" :prop="createTime">
							<el-input
								v-model="formData.createTime"
								placeholder="暂无审批发起时间"
								show-word-limit
								clearable
							/>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="18">
						<el-form-item label="审批状态" prop="spStatus">
							<el-select
								v-model="formData.spStatus"
								filterable
								placeholder="请选择合作公司"
								style="width: 100%"
							>
								<el-option
									v-for="(item, index) in spStatusOptions"
									:key="item.code"
									:label="item.name"
									:value="item.code"
								/>
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="18">
						<el-form-item label="审批完成时间" :prop="roleName">
							<el-input
								v-model="formData.finishTime"
								placeholder="暂无审批完成时间"
								maxlength="20"
								show-word-limit
								clearable
							/>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="18">
						<el-form-item label="审批渠道" :prop="channelName">
							<el-input
								v-model="formData.channelName"
								placeholder="请输入角色名称"
								maxlength="20"
								show-word-limit
								clearable
							/>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="18">
						<el-form-item label="提交状态" prop="status">
							<el-radio-group v-model="formData.status" :disabled="isDisabled">
								<el-radio v-for="(item, index) in statusOptions" :key="index" :label="item.code">
									{{ item.name }}
								</el-radio>
							</el-radio-group>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
		</div>
		<template #footer>
			<div class="dialog-footer">
				<el-button type="primary" :disabled="isDisabled" @click="onSubmit">保 存</el-button>
				<el-button :disabled="isDisabled" @click="onClose">关 闭</el-button>
			</div>
		</template>
	</el-dialog>
</template>

<script setup>
import Request from '@/http'
import { defineExpose, inject, reactive, getCurrentInstance, ref } from 'vue'

const formInstance = getCurrentInstance()

const $messageBox = inject('$messageBox')

const isShowDialog = ref(false)
const isDisabled = ref(false)
const isLoading = ref(false)
const formData = reactive({
	channelName: '钉钉审批',
})
const formRule = {}
const spStatusOptions = [
	{ name: '审批中', code: 1 },
	{ name: '已通过', code: 2 },
	{ name: '已驳回', code: 3 },
	{ name: '已撤销', code: 4 },
]
const statusOptions = [
	{ name: '成功', code: 1 },
	{ name: '失败', code: 0 },
]

function getDetail(id) {
	isLoading.value = true
	return Request({
		url: `suf4-system-service/approvalInfo/getById/${id}`,
		method: 'GET',
	})
		.then(res => {
			if (res.code === 200) {
				isLoading.value = false
				console.log('钉钉审批详情获取成功 ==>', res.data)
				Object.assign(formData, res.data)
				return true
			} else {
				throw new Error(res.msg)
			}
		})
		.catch(error => {
			console.log('钉钉审批详情获取失败 ==>', error)
			return false
		})
}

function detail(item) {
	getDetail(item.spNo)
	isShowDialog.value = true
}

function onSubmit() {
	onClose()
}

function onClose() {
	isShowDialog.value = false
}

defineExpose({ detail })
</script>

<style lang="less" scoped>
.role-dialog-container {
	width: 100%;
	height: 100%;
	overflow: hidden;
	.dialog-body {
		width: 100%;
		overflow: hidden;
		.radio-box {
			width: 100%;
			overflow: hidden;
		}
		.content-box {
			width: 100%;
			overflow: hidden;
		}
		.checkbox-box {
			width: 100%;
			margin-top: 10px;
			overflow: hidden;
			.checkbox-item {
				width: 100%;
				overflow: hidden;
				.item-children {
					width: 100%;
					overflow: hidden;
					.no-children-two {
						display: inline-block;
					}
					.children-one {
						margin-left: 25px;
					}
					.children-two {
						margin-left: 50px;
					}
				}
			}
		}
	}
}
</style>
